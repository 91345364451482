<template>
  <div class="mt-4">
    <div class="w-full h-4 bg-blue-900"></div>
    <div class="w-full bg-blue-50">
      <div class="container mx-auto sm:px-6 lg:px-8 flex justify-between py-4">
        <ul class="text-sm flex gap-4">
          <li><router-link :to="{name: 'Content', params: {id: 3}}">{{ $t('nav.about') }}</router-link></li>
          <li><router-link :to="{name: 'Content', params: {id: 8}}">{{ $t('nav.privacy') }}</router-link></li>
          <li><router-link :to="{name: 'Content', params: {id: 7}}">{{ $t('nav.terms') }}</router-link></li>
        </ul>
        <ul class="text-sm flex gap-4">
          <li><button @click="switchLanguage()">{{ $t('locale.otherTitle') }}</button></li>
          <li><router-link :to="{name: 'Content', params: {id: 6}}">{{ $t('nav.community') }}</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
    import { useI18n } from 'vue-i18n'
    import { useRouter } from "vue-router"
    import Tr from "@/i18n/translation"
  
    export default {
      setup() {
        const { t, locale } = useI18n()
  
        const supportedLocales = Tr.supportedLocales
  
        const router = useRouter()
  
        const switchLanguage = async () => {
          const newLocale = t('locale.other')
  
          await Tr.switchLanguage(newLocale)
  
          try {
            await router.replace({ params: { locale: newLocale } })
          } catch(e) {
            console.log(e)
            router.push("/")
          }
        }
  
        return { t, locale, supportedLocales, switchLanguage }
      }
    }
  </script>
