<template>
  <div class="container mx-auto p-4">
    <Carousel
      :items-to-show="1"
      v-model="currentSlide"
      class="carousel"
      :mouse-drag="false"
    >
      <Slide v-for="(item, index) in featured" :key="index">
        <div
          class="col-span-2 bg-blue-50 rounded-sm shadow-md p-5 mb-4 text-left md:h-80 overflow-hidden"
        >
          <div class="p-5 md:grid md:grid-cols-6 gap-10">
            <img class="rounded-sm" :src="item.CInfo_Image" />
            <div class="flex flex-col col-span-5">
              <router-link :to="{name: 'Search', query: {artist: item.itd_Desc3}}" class="font-semibold text-blue-700 hover:text-blue-900">{{ item.itd_Desc3 }}</router-link>
              <span class="">{{ item.itd_Desc4 }}</span>
              <span>{{ item.CInfo_Data }}</span>
            </div>
          </div>
        </div>
      </Slide>
    </Carousel>
    <Carousel
      class="hover:cursor-pointer"
      id="thumbnails"
      :items-to-show="6"
      :wrap-around="true"
      v-model="currentSlide"
      ref="carousel"
    >
      <Slide v-for="(item, index) in featured" :key="index">
        <div class="carousel__item" @click="slideTo(index)">
          <img
            :class="currentSlide == index ? 'scale-110 shadow-xl' : 'scale-90 shadow-sm hover:shadow-xl hover:scale-100'"
            class="rounded-sm aspect-square object-cover  m-5 h-40  transition-all"
            :src="item.CInfo_Image"
          />
        </div>
      </Slide>
    </Carousel>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import axios from "@/axios";
export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      featured: null,
      currentSlide: 0
    };
  },
  methods: {
    slideTo(val) {
      this.currentSlide = val;
    },
    async getContent() {
      await axios
        .get("/public/featured", {
          params: {
            
          Flag: 4
          }
        })
        .then((response) => {
          this.featured = response.data.Table;
        });
    }
  },
  mounted() {
    this.getContent();
  }
};
</script>
