<template>
  <div class="home">
    <FeaturedItems></FeaturedItems>
  </div>
</template>

<script>
// @ is an alias to /src
import FeaturedItems from '@/components/FeaturedItems.vue'

export default {
  name: 'HomeView',
  components: {
    FeaturedItems
  }
}
</script>
