import axios from "axios";
//axios.defaults.baseURL = "https://matrix.fab.ca/v1.0";
//axios.defaults.baseURL = "https://localhost:44316/v1.0";


const axiosInstance = axios.create({
    baseURL: 'https://matrix.fab.ca/v1.0', // Set your API base URL
});

axiosInstance.interceptors.request.use(
    config => {
        const accessToken = localStorage.getItem('token');
        if (accessToken && accessToken != "" && accessToken != "null") {
            config.headers['accesstoken'] = accessToken;
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        // If the response is successful, simply return the response
        return response;
    },
    async error => {
        const originalRequest = error.config;
        // Check if the error status is 401 (Unauthorized) and the request is not a retry
        if (error.response.status === 401  && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                // Attempt to refresh the token
                const refreshToken = localStorage.getItem('refresh');
                const userString = localStorage.getItem('user');
                const user = JSON.parse(userString);
                const { data } = await axios.post('https://matrix.fab.ca/v1.0/v1.0/authorize/LoginRenew', { RefreshToken: refreshToken, Username: user.UserName });
                
                // Save the new tokens
                localStorage.setItem('token', data.token);
                localStorage.setItem('refresh', data.refreshToken);

                // Update the original request with the new token
                axiosInstance.defaults.headers['Authorization'] = `Bearer ${data.token}`;
                originalRequest.headers['Authorization'] = `Bearer ${data.token}`;

                // Retry the original request with the new token
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                // Handle errors during the token refresh process
                console.log("HIT")
                window.location.reload(true);
                return Promise.reject(refreshError);
            }
        }

        // If the error is not a 401 or is unhandled, reject the promise
        return Promise.reject(error);
    }
);

export default axiosInstance;
//axios.defaults.headers.common["accesstoken"] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50IjoiMTA3IiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6InRpbXJvem9uQGdtYWlsLmNvbXh4eCIsImp0aSI6IjA5NThkM2FmLTY1NGQtNDM3ZC1iMjMwLTM4YWNmYTE4YTg0ZiIsIm5iZiI6MTY1MzA2MDYyMCwiZXhwIjoxNjUzMDY0MjIwLCJpc3MiOiJDdXN0b21lcmFwaS5jb20iLCJhdWQiOiJTYW1wbGVBdWRpZW5jZSJ9.SDMbIhzZdZ3sEAftnG10vKWwZ17z_B_Sr2AZu8mkEqQ";