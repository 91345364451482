<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Disclosure as="nav" class="bg-blue-50 shadow" v-slot="{ open }">
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <button
            v-if="mainStore.isAdmin"
            type="button"
            class="px-5 md:mx-3 border-r border-gray-200 text-gray-500 hover:bg-slate-100 transition-all focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            @click="mainStore.sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
          </button>
            <!-- Mobile menu button -->
            <DisclosureButton
              class="px-5 mr-2 md:hidden text-gray-500 transition-all focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          <div class="flex-shrink-0 flex items-center">
            <img
              class="block lg:hidden h-8 w-auto"
              src="@/assets/logos/logo-fab-letters.png"
              alt="FAB"
            />
            <img
              class="hidden lg:block h-8 w-auto"
              src="@/assets/logos/logo-fab.png"
              alt="FAB"
            />
          </div>
          <div class="hidden md:ml-6 md:flex md:space-x-8">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <router-link
              :to="{ name: 'Home' }"
              class="border-transparent inline-flex text-gray-700 hover:border-gray-300 items-center px-1 pt-1 border-b-2 text-sm font-bold"
              active-class="border-indigo-500"
            >
              {{ $t('nav.home') }}
            </router-link>
            <router-link
              :to="{ name: 'Digest' }"
              class="border-transparent text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-bold"
              active-class="border-indigo-500"
            >
            {{ $t('nav.digest') }}
            </router-link>
            <router-link
            v-if="mainStore.isAuth"
              :to="{ name: 'SalesSheet' }"
              class="border-transparent text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-bold"
              active-class="border-indigo-500"
            >
            {{ $t('nav.salesSheet') }}
            </router-link>
            <Menu v-if="mainStore.isAuth" as="div" class="relative inline-flex text-left">
              <div
                class="inline-flex border-transparent items-center px-1 pt-1 border-b-2 text-sm hover:border-gray-300"
              >
                <MenuButton class="text-gray-700 inline-flex font-bold">
                  <a>{{ $t('nav.more') }}</a>
                  <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 top-14 w-56 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                >
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'ShipThisWeek' }"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.thisWeek') }}</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'ShipNextWeek' }"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.nextWeek') }}</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'StaffPicks' }"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.staffPicks') }}</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{name: 'TourInfo'}"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.tourDates') }}</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{name: 'ItemSale'}"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.itemSale') }}</router-link
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
        <div class="hidden md:ml-6 md:flex md:space-x-8">
            <router-link
            v-if="mainStore.isAuth"
              to="/search"
              class="border-transparent text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              <SearchIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              <span>{{ $t('nav.search') }}</span>
            </router-link>
          <template
            v-if="user == true"
          >
            <button
              :disabled="cartStore.header?.CartCount==0 || cartStore.header == ''"
              @click="$router.push({ name: 'Cart' })"
              type="button"
              class="border-transparent relative text-gray-500 hover:border-gray-300 px-1 pt-1 border-b-2"
            >
              <span class="sr-only">Cart</span>
              <ShoppingCartIcon class="h-6 w-6" aria-hidden="true" />
              <span class="absolute px-2 py-0.5 top-2 left-4 rounded-full text-xs font-medium bg-blue-100 text-blue-900"> {{cartStore.header?.CartCount ? cartStore.header.CartCount : 0}} </span>
            </button>

            <!-- Profile dropdown -->
            <Menu as="div" class="border-transparent relative text-gray-700 hover:border-gray-300 px-1 pt-1 border-b-2 text-sm font-medium">
                <MenuButton class="inline-flex h-full items-center"
                >
                  <span>{{ mainStore.user.Email }}</span>
                  <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-sm shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                >
                  <MenuItem v-slot="{ active }">
                    <router-link
                      :to="{name: 'HistoryOrders'}"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      ]"
                      >{{ $t('nav.orderHistory') }}</router-link
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <router-link
                      :to="{ name: 'Location' }"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      ]"
                    >
                      {{
                        mainStore.locationName
                          ? mainStore.locationName + $t('nav.changeLocation')
                          : $t('nav.selectLocation')
                      }}</router-link
                    >
                  </MenuItem>
                  <MenuItem as="a" @click="signOut()" v-slot="{ active }">
                    <a
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                      ]"
                      >{{ $t('nav.signOut') }}</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </template>
            <button
            v-if="user == false"
              @click="$router.push({ name: 'SignIn' })"
              type="button"
              class="transition-colors relative inline-flex items-center px-4 my-3 border border-transparent text-sm font-medium rounded-sm text-white bg-orange-500 shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              <span>{{ $t('nav.signIn') }}</span>
            </button>
        </div>
      </div>
      <BreadCrumb class="hidden"></BreadCrumb>
    </div>

    <DisclosurePanel class="md:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
        <router-link
          :to="{name: 'Home'}"
          class="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >Home</router-link>
        <router-link
        :to="{name: 'Digest'}"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >Digest</router-link
        >
        <router-link
        :to="{name: 'SalesSheet'}"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >Sales Sheet</router-link
        >
        <router-link
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >More</router-link
        >
      </div>
      <div class="pt-4 pb-3 border-t border-gray-200">
        <div class="flex items-center px-4 sm:px-6">
          <div class="ml-3">
            <div class="text-sm font-medium text-gray-500"><span>{{ mainStore.user.Email }}</span></div>
          </div>
          <button
            type="button"
            class="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="sr-only">View notifications</span>
          </button>
        </div>
        <div class="mt-3 space-y-1">
          <DisclosureButton
            as="a"
            href="#"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
            >Your Profile</DisclosureButton
          >
          <DisclosureButton
            as="a"
            href="#"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
            >Settings</DisclosureButton
          >
          <DisclosureButton
            as="a"
            href="#"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
            >Sign out</DisclosureButton
          >
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import { useMainStore } from "@/stores/mainStore";
import {useCartStore} from "@/stores/cartStore"
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from "@headlessui/vue";
import {
  ShoppingCartIcon,
  MenuIcon,
  XIcon,
  MenuAlt2Icon,
  SearchIcon
} from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ShoppingCartIcon,
    MenuIcon,
    SearchIcon,
    XIcon,
    BreadCrumb,
    MenuAlt2Icon,
    ChevronDownIcon
  },
  setup() {
    const mainStore = useMainStore();
    const cartStore = useCartStore();
    return { mainStore, cartStore };
  },
  computed: {
    user() {
      if (this.mainStore.user) {
        return true;
      }
      return false;
    }
  },

  methods: {
    signOut() {
      this.mainStore.signOut();
    }
  }
};
</script>
