import axios from '@/axios';
import { defineStore } from 'pinia'
import { useMainStore } from './mainStore';

export const useCartStore = defineStore("cart", {
  state: () => {
    return {
      items: [],
      qtys: [],
      loading: false,
      header: {}
    };
  },
  actions: {
    //Add items to pinia and DB cart
      async addToCart(item, qty) {
        const mainStore = useMainStore();
        try {
          await axios.post("/SP/Post/QComm_WriteCartDetail_v3", {
            OrderNo: mainStore.orderNo,
            CustCode: mainStore.workingCustCode,
            OrderType: "CO",
            UserName: mainStore.user.UserName,
            ItemCode: item,
            QtyOrdered: qty,
            Upd_UserName: mainStore.user.UserName,
            UserLangCode: 1,
            ShipToCode: mainStore.location

          })
          if (!mainStore.orderNo) {
            await mainStore.getOrderNo()
          }
          this.getCartHeader();
        } catch(e) {e}
      },
      //Request details of cart from DB
      async getCart() {
        this.loading = true;
        const mainStore = useMainStore();
        await mainStore.getOrderNo();
        await axios.post("/SP/Post/QComm_GetOrderDetails", {
          OrderNo: mainStore.orderNo,
          LangCode: 1
        }).then(response => this.items = response.data.Table)
        this.qtys = this.items?.map(e => e.od_QtyOrdered)
        
        this.loading = false;
      },
      async getCartHeader() {
        const mainStore = useMainStore();
        try {
          if(!mainStore.orderNo) {
            this.header = {}
            return;
          }
          const response = await axios.post("/SP/Post/QComm_GetOrderHeader", {
            OrderNo: mainStore.orderNo,
            CustCode: mainStore.workingCustCode,
            UserName: mainStore.user.UserName,
            LangCode: 1,

          })
          this.header = response.data?.Table?.[0]
        } catch(e) {e}
      },
      async reload() {
        await this.getCartHeader();
        await this.getCart();
      },
      //Removes the item from pinia and the DB, but does not re request the cart
      async removeFromCart(item) {
        const mainStore = useMainStore();
        try {
          await axios.post("/SP/Post/QComm_WriteCartDetail_v3", {
            OrderNo: mainStore.orderNo,
            CustCode: mainStore.workingCustCode,
            OrderType: "CO",
            UserName: mainStore.user.UserName,
            ItemCode: item,
            QtyOrdered: 0,
            Upd_UserName: mainStore.user.UserName,
            UserLangCode: 1,
            ShipToCode: mainStore.location

          })
        } catch(e) {e}
          let i = this.items.findIndex(e => e.od_ItemCode == item);
          this.items.splice(i, 1);
          this.qtys.splice(i,1);
      },
      
  },
});
