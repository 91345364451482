<template>
  <div class="flex flex-col min-h-screen">
    <div id="nav">
      <SideBar></SideBar>
      <NavBar v-if="!$route.meta.hideNavbar"></NavBar>
    </div>
    <div class="flex-grow">
      <router-view />
    </div>
    <FooterBar
      v-if="$route.meta.requiresAdmin != true && $route.meta.hideFooter != true"
    />
  </div>
</template>
<script>
import NavBar from "./components/NavBar.vue";
import SideBar from "./components/SideBar.vue";
import FooterBar from "./components/FooterBar.vue";
import { useMainStore } from "@/stores/mainStore";
export default {
  components: {
    NavBar,
    SideBar,
    FooterBar
  },
  setup() {
    const mainStore = useMainStore();
    return {
      mainStore
    };
  },
  data() {
    return {
      loading: false
    };
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a.router-link-exact-active {
  color: #f97316;
}
</style>
